

<template>
    <el-dialog title="选择跳转链接" :visible.sync="currenurlVisible" @close="close" class="dial">
        <!-- 表格内容 -->
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane name="1" label="商品分类">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="一级分类:">
                            <el-select v-model="fid" clearable @change="getBlist">
                                <el-option v-for="item in Alist" :label="item.name+'('+item.id+')'" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="二级分类:">
                            <el-select ref="refB" v-model="queryUrlParams.pid2" placeholder="全部" clearable @change="getClist(queryUrlParams.pid2)">
                                <el-option v-for="item in Blist" :label="item.name+'('+item.id+')'" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <div style="float: right">
                            <el-button type="primary" @click="loadData()">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="sortList.list" highlight-current-row>
                        <el-table-column prop="name" label="标题" align="center"></el-table-column>
                        <el-table-column label="状态" prop="state" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag class="point" type="success" @click="choseGoodsClassify(scope.row.id,scope.row.level)">选中</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="sortList" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
                </el-tab-pane>
                <el-tab-pane name="2" label="品牌专场">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="专场名称:">
                            <el-input v-model="queryUrlParams.name" placeholder="请输入专场名称" clearable>
                            </el-input>
                        </el-form-item>

                        <div style="float: right">
                            <el-button type="primary" @click="loadSpecialData()">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="specialList.list" highlight-current-row>

                        <el-table-column prop="name" label="标题" align="center"></el-table-column>
                        <!-- <el-table-column label="位置" prop="position_name" align="center" width="120"></el-table-column>
                                    <el-table-column label="平台" prop="platform_name" align="center" width="120"></el-table-column>
                                    <el-table-column label="开始时间" prop="ctime" width="180" align="center"></el-table-column>
                                    <el-table-column prop="etime" label="结束时间" width="180" align="center"></el-table-column>-->
                        <el-table-column label="状态" prop="state" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" prop="path" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag class="point" type="success" @click="chose(scope.row.id)">选中</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="specialList" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
                </el-tab-pane>
                <el-tab-pane name="3" label="套装购">
                    <el-form :inline="true" class="demo-form-inline" label-suffix="：" ref="form">
                        <el-form-item label="活动名称">
                            <el-input v-model="queryUrlParams.name" placeholder="请输入活动名称" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="queryUrlParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                                <el-option label="上线" :value="2"></el-option>
                                <el-option label="下线" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="位置" prop="position">
                            <el-select v-model="queryUrlParams.position" placeholder="请选择位置" @change="changeQuery" clearable>
                                <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="时间">
                            <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryUrlParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                            </el-date-picker>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="loadSuitData()">搜索</el-button>
                        </div>
                    </el-form>

                    <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="suitList.list" highlight-current-row>
                        <el-table-column label="id" prop="id" align="center" width="100"></el-table-column>
                        <el-table-column label="活动名称" prop="name" align="center" width="200"></el-table-column>
                        <el-table-column label="logo" align="center" width="80" prop="logo">
                            <template slot-scope="scope">
                                <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" fit="cover" class="table-td-thumb" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                                    <div slot="error" class="image-slot">
                                        <el-image fit="cover" :src="defaultImg"></el-image>
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="专场描述" prop="desc" align="center"></el-table-column>
                        <el-table-column label="位置" prop=" position_name" align="center" width="120"></el-table-column>
                        <el-table-column label="开始时间" prop="stime" width="180" align="center"></el-table-column>
                        <el-table-column label="结束时间" prop="etime" width="180" align="center"></el-table-column>
                        <el-table-column label="活动类型" prop="ty" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag type="primary" v-if="scope.row.ty === 1">n件n元</el-tag>
                                <el-tag type="primary" v-if="scope.row.ty === 2"> n件n折</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="能否使用优惠券" prop="is_coupon" align="center" width="120">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.is_coupon === 1">不可用</el-tag>
                                <el-tag type="primary" v-if="scope.row.is_coupon === 2">可用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" prop="state" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag class="point" type="success" @click="chose(scope.row.id)">选中</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pagination v-bind:pageInfo="suitList" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
                </el-tab-pane>
            </el-tabs>

        </div>
    </el-dialog>
</template>
<script>
import { request } from '@/common/request';
import Pagination from '@/components/Pagination';
import { deal } from '@/common/main';
/* 再此定义JS，采用ES6标准格式 */
export default {
    name: 'Url',
    components: {
        Pagination
    },
    props: ['urlVisible'], // 声明自定义属性
    data() {
        return {
            defaultImg: require('../assets/img/zhan.jpeg'),
            sortList: [],
            activeName: '1',
            stretch: true,
            loading: false,
            queryUrlParams: {
                state: 2,
                page: 1,
                pagesize: 10
            },
            currenurlVisible: false,
            path: '',
            url: '',
            urlList: [],
            fid: 0,
            Alist: [{ id: 0, name: "全部" }],//一级分类
            Blist: [],//二级分类
            specialList: [],
            suitList: [],
            positionList: [],
        };
    },
    mounted() {
        this.loadUrl(1)
        this.loadData(0)
        this.loadAllData()
    },
    watch: {
        // 监听 addOrUpdateVisible 改变
        urlVisible(oldVal, newVal) {
            this.currenurlVisible = this.urlVisible
        },
    },
    methods: {
        // url选择
        chose(id) {
            console.log(88, this.path, id)
            let path = `${this.path}${id}`
            console.log(66666, path)
            if (path) {
                this.$emit('getUrl', path);
            }
            this.close()
        },
        // url选择
        choseGoodsClassify(id, level) {
            console.log(88, this.path, id)
            let cateLevelId = ''
            if (level === 1) {
                cateLevelId = "pcate"  //first id
            }
            else if(level === 2) {
              cateLevelId = "cate"  //second id
            }
            else if (level === 3) {
              cateLevelId = "tcate"  //third id
            }
            let path = `${this.path}${cateLevelId}=${id}`
            console.log('path is ', path)
            if (path) {
                this.$emit('getUrl', path);
            }
            this.close()
        },
        close() {
            this.$emit('toggle', false);
        },
        // tab选择
        handleClick(tab, event) {
            if (tab.paneName === '1') {
                this.queryUrlParams.ty = 1
                this.loadUrl(1)
                this.loadData(0)

            } else if (tab.paneName === '2') {
                this.queryUrlParams.ty = 2
                this.loadUrl(2)
                this.loadSpecialData()
            } else if (tab.paneName === '3') {
                this.queryUrlParams.ty = 3
                this.loadUrl(3)
                deal.getconf('suit', this)
                this.loadSuitData()
            }
        },
        // 商品分类
        loadData(id) {
            const _this = this
            this.loading = true;
            if (id) {
                this.queryUrlParams.pid = id
            }
            // 获取商品分类列表
            request.get('/goods/classify/list', this.queryUrlParams).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.sortList = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 专场
        loadSpecialData() {
            request.get('/goods/special/list', this.queryUrlParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.specialList = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 套装购
        loadSuitData() {
            delete this.queryUrlParams.ty
            request.get('/goods/suit/list', this.queryUrlParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.suitList = ret.data;
                    this.suitList.list.map(function (val) {
                        val.stime = deal.timestamp(val.stime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //获取跳转链接
        loadUrl(ty) {
            this.loading = true
            request.get('/common/route/find', { ty: ty, state: 2 }).then(ret => {
                if (ret.code === 1) {
                    this.loading = false
                    console.log(4444, ret.data.list.path)
                    this.path = ret.data.list.path;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryUrlParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryUrlParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryUrlParams.page = val;
            this.loadData();
        },
        getClist(id) {
            this.queryUrlParams.pid1 = this.Aid
            this.loadData(id) // 获取三级分页分类
        },
        getBlist() {
            this.queryUrlParams.pid = this.fid;
            this.queryUrlParams.pid2 = this.fid;
            this.Blist = [{ id: this.fid, name: "全部" }];
            this.loadBllData() // 获取二级全部分类
            this.loadData() // 获取二级分页分类
        },
        //一级全部列表
        loadAllData() {
            let queryUrlParams = {
                pid: 0,
                page: 1,
                state: 2,
                pagesize: -1,
                platform: 'admin'
            }
            request.get('/goods/classify/list', queryUrlParams).then(ret => {
                if (ret.code == 1) {
                    this.Alist = this.Alist.concat(ret.data.list);
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },
        //二级全部列表
        loadBllData() {
            let queryUrlParams = {
                state: 2,
                pid: this.fid,
                page: 1,
                pagesize: -1,
                platform: 'admin'
            }
            request.get('/goods/classify/list', queryUrlParams).then(ret => {
                if (ret.code == 1) {

                    this.Blist = this.Blist.concat(ret.data.list);
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },


    }
}
</script>
<style scoped>
/* 在此定义当前页面css */
.name {
    margin-right: 10px;
}

.block .el-col {
    margin-bottom: 20px;
}
.dial >>> .el-dialog {
    width: 80%;
}
.point {
    cursor: pointer;
}
</style>>
