/**
 *  校验规格名称是否包含非法字符
 * @param specTitle String
 */
export function checkSpecContainInvalidStr(specTitle) {
    console.log(specTitle)
    if (specTitle.includes(',')) {
        return '不能包含 , 字符'
    } else if (specTitle.includes('-')) {
        return '不能包含 - 字符'
    }
    return null;
}
