<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="客户名称:">
                    <el-input class="s-input" v-model="queryParams.username" placeholder="请输入客户名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户id:">
                    <el-input class="s-input" v-model="queryParams.uid" placeholder="请输入用户id" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                    <el-input class="s-input" v-model="queryParams.phone" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <el-form-item label="选择性别:">
                    <el-select v-model="queryParams.sex" placeholder="请选择性别" clearable @change="changeQuery">
                        <el-option label="女" :value="2"></el-option>
                        <el-option label="男" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户等级:">
                    <el-select v-model="queryParams.enterprise_id" placeholder="请选来源企业" :clearable="true" @change="changeQuery">
                        <el-option v-for="items in enterpriseList" :key="items.id" :label="items.name" :value="items.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户等级:">
                    <el-select v-model="queryParams.grade_id" placeholder="请选择用户等级" clearable @change="changeQuery">
                        <el-option v-for="items in rankList" :key="items.id" :label="items.name" :value="items.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="禁用" :value="1"></el-option>
                        <el-option label="启用" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="起止时间:" prop="time">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div style="margin: 10px 0">
                <el-button type="primary" @click="weixinAppPush()">微信H5推送</el-button>
                <el-button type="primary" @click="weixinAppletPush()">微信小程序推送</el-button>
                <el-button type="primary" @click="exportFile">导出</el-button>

            </div>
            <el-table @select="selectgoods" @select-all="selectgoods" v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column type="selection"></el-table-column>

              <el-table-column label="客户信息" fixed="left" width="180">
                <template slot-scope="scope">
                  <div class="head-box">
                    <!--  头像  -->
                    <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px" fit="cover" :class="`column${scope.$index}`" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]">
                      <div slot="error" class="image-slot">
                        <el-image fit="cover" :src="defaultImg"></el-image>
                      </div>
                    </el-image>
                    <div class="msg-box">
                      <!--  姓名 -->
                      <span class="name">{{scope.row.username}}</span>
                        <el-tag class="name">{{scope.row.source}}</el-tag>
                      <!--  手机号码 -->
                      <div class="tel">{{scope.row.phone}}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
                <el-table-column label="来源企业" prop="enterprise_id" width="120" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.enterprise" type="success">{{scope.row.enterprise.name}}</el-tag>
                    </template>
                </el-table-column>
              <el-table-column label="等级" prop="grade_name" width="120" align="center"></el-table-column>
              <el-table-column label="累计销售(单)" prop="order_num" width="150" align="center"></el-table-column>
              <el-table-column label="累计销售金额" prop="sales_price" width="150" align="center"></el-table-column>
              <el-table-column label="团队人数" prop="team_added" width="150" align="center"></el-table-column>
              <el-table-column label="累计消费(单)" prop="order_num" width="150" align="center"></el-table-column>
              <el-table-column label="当前余额" prop="wallet.money" width="120" align="center"></el-table-column>
              <el-table-column label="当前积分" prop="wallet.integral" width="120" align="center"></el-table-column>
              <el-table-column label="最近消费" prop="ctime" width="200" align="center"></el-table-column>
              <el-table-column label="获客时间" prop="ctime" width="200" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" width="80" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 1">禁用</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 2">启用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="xiangqi(scope.$index, scope.row)">详情
                        </el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">禁用
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            启用</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 新增 编辑 -->
            <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
                <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
                    <el-form-item label="客户名称 " prop="username">
                        <el-input v-model="form.username"></el-input>
                    </el-form-item>
                    <el-form-item label="昵称 " prop="nickname">
                        <el-input v-model="form.nickname"></el-input>
                    </el-form-item>
                    <el-form-item label="推荐人 " prop="agent_id">
                        <el-input v-model="form.agent_id" placeholder="请输入推荐人"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入手机号" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="注册来源" prop="source">
                        <el-input v-model="form.source" placeholder="请输入注册来源" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="remarks">
                        <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <!-- 图片上传 -->
                    <el-form-item label="头像" prop="avatar">
                        <el-input v-model="form.avatar" placeholder="请输入头像链接"></el-input>
                        <el-button @click.prevent="choiceImg">选择图片</el-button>
                        <el-upload class="avatar-uploader up" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                            <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">建议尺寸100*100像素</span>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="form.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="状态" prop="state">
                        <el-radio-group v-model="form.state">
                            <el-radio :label="2">启用</el-radio>
                            <el-radio :label="1">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="关联分销商" prop="state">
                        <el-button @click.prevent="loadDistributor(form.distributor_id)" type="primary">关联</el-button>
                    </el-form-item>
<!--                    <div v-if="selectDistributor!=null&& selectDistributor.id!==undefined">-->

<!--                        <el-form-item label="已关联分销商" prop="state" >-->
<!--                            <div class="distributor-name" >{{ selectDistributor.name }} | {{ selectDistributor.billName }}</div>-->
<!--                            <div>{{ selectDistributor.salesmanName }} | {{ selectDistributor.salesmanMobile }}</div>-->
<!--                            <div>{{ selectDistributor.receiverName }} | {{ selectDistributor.receiverMobile }}</div>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
        <!-- 选择分销商对话框 -->
        <div class="url">
            <Distributor :urlVisible="distributorVisible" :urlList="distributorList" @toggle="toggle"  @listenChoiceDistributor="listenChoiceDistributor" @getUrl="getDistributor"></Distributor>
        </div>
        <!--打标签对话框 -->
        <!-- <el-dialog :title="title" :visible.sync="showTag" width="50%">
            <div slot="footer" class="dialog-footer">
                <el-button @click="showTag = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>-->
        <!--送券对话框-->
        <!-- <el-dialog :title="title" :visible.sync="showCoupon" width="50%">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading"
                label-position="right" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <el-form-item label="名称" prop="name">
                    <el-input type="text" auto-complete="off" v-model="form.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-select v-model="form.state" placeholder>
                        <el-option label="可用" v-bind:value="2"></el-option>
                        <el-option label="不可用" v-bind:value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showCoupon = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>-->
        <!-- 黑名单对话框 -->
        <!-- <el-dialog :title="title" :visible.sync="showBlacklist" width="50%">
            <div slot="footer" class="dialog-footer">
                <el-button @click="showBlacklist = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>-->
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';
import loadEvents from '@/utils/loading'
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
import Distributor from "@/components/Distributor"

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture,
        Distributor,
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            rankList: [],
           enterpriseList: [],
            queryTagParams: {},
            tagPopover: false,
            showTag: false,
            couponPopover: false,
            showCoupon: false,
            confirmVisible: false,
            blacklistPopover: false,
            showBlacklist: false,
            confirmContent: '',
            imageUrl: '',
            menuList: ['客户', '客户管理'],
            title: '加载中',
            addVisible: false,
            selectionList: [], //选中客户
            mode: 'add',
            //关联分销商
            distributorVisible:false,
            distributorList:[],
            selectDistributor:{},
            form: {
                state: 1
            },
            //验证规则
            rules: {
                username: [{
                    required: true,
                    message: '客户名称不能为空',
                    trigger: 'blur'
                }],
                nickname: [{
                    required: true,
                    message: '昵称不能为空',
                    trigger: 'blur'
                }],
                agent_id: [{
                    required: true,
                    message: '推荐人不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字"
                }],
                avatar: [{
                    required: false,
                    trigger: 'blur'
                },
                { validator: deal.checkImage, trigger: ['blur', 'change'] }
                ],
                sex: [{
                    required: true,
                    message: '性别不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                phone: [{
                    required: false,
                    message: '手机号不能为空',
                    trigger: 'blur'
                },
                {
                    min: 11,
                    max: 11,
                    message: "请输入11位手机号码",
                    trigger: "blur"
                },
                {
                    pattern: /^1[0-9]{10}$/,
                    message: "请输入正确的手机号码"
                }],
            },
            header: {},
            loading: false,
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            pickerDatad: []
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData()
        this.getRankList()
        this.getEnterpriseList()
    },
    methods: {
        // 导出
        exportFile() {
            if (this.queryParams.ctime && this.queryParams.etime) {
                request.get('/user/export', { stime: this.queryParams.ctime, etime: this.queryParams.etime }).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success('导出成功');
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            } else {
                this.$message.error('请选择时间');
            }

        },

        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'avatar', url);
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },

        // 获取用户等级
        getRankList() {
            request.get('/account/grade/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.rankList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        getEnterpriseList() {
            request.get('/enterprise/list', { page: 1, pagesize: -1}).then(ret => {
                if (ret.code === 1) {
                    this.enterpriseList = ret.data.list;
                    this.enterpriseList.push()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        add() {
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 0,
                sex: 1
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'avatar', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否启用?';
            } else {
                this.confirmContent = '确定是否禁用?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        //加入黑名单
        // open () {
        //     this.$confirm('确认将已选择客户加入黑名单？加入黑名单后请去黑名单模块管理',
        //         '加入黑名单确认示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         let arr = this.selectionList.map(item =>
        //             item.nickname
        //         )
        //         arr = arr.join(',')
        //         console.log(777, arr)
        //         this.$message({
        //             type: 'success',
        //             message: '添加成功!'
        //         });
        //     }).catch(() => {
        //         this.$message({
        //             type: 'info',
        //             message: '已取消添加'
        //         });
        //     });
        // },
        //详情
        xiangqi(index, row) {
            this.$router.push({ path: '/custom-Detail', query: { id: row.id } })
        },
        //编辑
        edit(index, row) {
            this.selectDistributor = {}
            this.mode = 'edit';
            this.title = '编辑客户';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form);
            this.form.agent_id = this.form.agent_id + ''
            this.addVisible = true;
            // 载入关联的分销商
            this.getDistributor(row.id)
        },
        // 编辑新增
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url =  '/account/edit';
                        //关联供应
                        if (this.selectDistributor && this.selectDistributor.id !== undefined && this.selectDistributor.id > 0) {
                            this.form.distributor_id = this.selectDistributor.id
                        }
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code === 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode === 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        loadData() {
            this.loading = true;
            request.get('/account/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 确定禁用
        stateData() {
            request.post('/account/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //选择商品
        selectgoods(selection, row) {
            this.selectionList = selection

        },
        changeQuery() {
            this.queryParams.page = 1
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            }
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            }
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            }
            this.loadData()
        },
        // 消息推送
        weixinAppPush() {


        },
        weixinAppletPush() {

        },

        //分销商列表
        loadDistributor(distributorId) {
            this.selectDistributor={} //重置
            this.distributorVisible = true
        },
        toggle(val) { // guanbi url对话框
            this.distributorVisible = val;
        },
        //获取分销商列表
        getDistributor(id) { // 获取选择的分销商


            request.get('/distributor/get', {id:id}).then(ret => {
                if (ret.code === 1) {

                    this.selectDistributor = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //监听子组件 提交的分销商的id
        listenChoiceDistributor(distributor){
            console.log( "--------listenChoiceDistributor---------")
            console.table( distributor)
            this.selectDistributor=distributor
        },
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}

.distributor-name{
    font-size: 20px;
    font-weight: bold;
}
</style>
