<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="分类名称:">
                    <el-input placeholder="请输入分类名称" clearable v-model="queryParams.name"></el-input>
                </el-form-item>
                <el-form-item label="一级分类:">
                    <el-select v-model="fid" clearable @change="getBlist">
                        <el-option v-for="item in Alist" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级分类:">
                    <el-select ref="refB" v-model="queryParams.pid2" placeholder="全部" clearable @change="getClist(queryParams.pid2)">
                        <el-option v-for="item in Blist" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
                <div class="action">
                    <el-button type="primary" @click="add()">新增</el-button>
<!--                    <el-upload style="display:inline-block;margin-left:10px" class="upload-demo"
                               action="/i/admin/goods/classify/import" :show-file-list="false"
                               :on-success="handleImportSuccess" :before-upload="beforeImportUpload" name="file">
                      <el-button size="small" type="success" >导入分类</el-button>
                    </el-upload>-->
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list">
                <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                <el-table-column label="排序" prop="r" align="center" width="150"></el-table-column>
                <el-table-column label="分类名称" prop="name" align="center"></el-table-column>
                <el-table-column label="图片" align="center" width="180" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" :class="`column${scope.$index}`" :src="scope.row.image" :preview-src-list="[scope.row.image]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="横幅banner" align="center" width="180" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" :class="`column${scope.$index}`" :src="scope.row.banner" :preview-src-list="[scope.row.banner]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="父级分类" prop="pid" align="center" width="150"></el-table-column>
                <el-table-column label="类目等级" prop="level" align="center" width="150"></el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 0">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.level <3 " type="text" icon="el-icon-plus" @click="addChild(scope.row)">新增子分类</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 0)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 0" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            <!-- 编辑 -->
            <el-dialog :title="title" :visible.sync="addVisible" width="50%" class="dialog_box" v-if="addVisible">
                <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                    <el-form-item label="排序 " prop="r">
                        <el-input v-model="form.r" placeholder="数字越大越靠前" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="name">
                        <el-input type="text" auto-complete="off" v-model="form.name" placeholder="请输入分类名称"></el-input>
                    </el-form-item>
                    <el-form-item label="父级分类" prop="pid">
                        <el-input type="text" auto-complete="off" v-model="form.pid" placeholder="请输入父级id"></el-input>
                    </el-form-item>
                    <el-form-item label="类目等级" prop="level" >
                        <el-select v-model="form.level" placeholder="请选择类目等级" clearable >
                            <el-option label="1" value="1"></el-option>
                            <el-option label="2" value="2"></el-option>
                            <el-option label="3" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- //图片上传 -->
                    <el-form-item label="图片" prop="image">
                        <el-input v-model="form.image" placeholder="请输入图片链接"></el-input>
                        <el-button @click.prevent="choiceImg">选择图片</el-button>
                        <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header" style="margin-top:30px;">
                            <img v-if="form.image" :src="form.image" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">建议尺寸750*300像素</span>
                    </el-form-item>
                    <!--    //图片上传    -->
                    <el-form-item label="横幅banner" prop="">
                        <el-input v-model="form.banner" placeholder="请输入图片链接"></el-input>
                        <el-button @click.prevent="choiceBanner">选择图片</el-button>
                        <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleBannerSuccess" :before-upload="beforeBannerUpload" name="file" :headers="header" style="margin-top:30px;">
                            <img v-if="form.banner" :src="form.banner" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span class="span_gray">非必选</span>
                    </el-form-item>
                    <el-form-item label="状态" prop="state" style="margin-top:-10px;">
                        <el-radio-group v-model="form.state">
                            <el-radio :label="2">上线</el-radio>
                            <el-radio :label="0">下线</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveData">提 交</el-button>
                </div>
            </el-dialog>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 选择图片对话框 -->
            <div class="img">
                <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
            </div>

            <!-- 选择图片对话框 -->
            <div class="img">
                <Picture :ImgVisible="bannerVisible" @toggleImg="toggleBanner" @getImg="getBanner"></Picture>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import Picture from '@/components/Picture';
import {deal} from '@/common/main';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
import loadEvents from '@/utils/loading'

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            mode:"add", // add   | edit
            addType:"normal", //add | addChild
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            bannerVisible: false,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            menuList: ['商品', '商品分类'],
            confirmVisible: false,
            confirmContent: '',
            pickerOptions: {
                disabledDate(time) {
                    // return time.getTime() < Date.now() - 8.64e7;
                }
            },
            Alist: [{ id: 0, name: "全部" }],//一级分类
            Blist: [],//二级分类
            fid: 0,
            Aid: null,
            loading: false,
            queryParams: {
                pid: 0,
                pid1: null,
                pid2: null,
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            rules: {
                r: [{
                    required: true,
                    message: '序号不能为空',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: '分类名称不能为空',
                    trigger: 'blur'
                }],
                pid: [{
                    required: true,
                    message: '父级分类不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                level: [{
                    required: true,
                    message: '类目等级不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                image: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            form: {
                name: '',
                state: 2,
                time: null
            },
        }
    },
    created() {
        this.loadAllData()
        this.loadData(0);
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        toggleBanner(val) { // 关闭 banner对话框
            this.bannerVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        choiceBanner() {
            this.bannerVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'image', url);
        },
        getBanner(url) {// 获取选择的img
            this.$set(this.form, 'banner', url);
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },

        // 图片banner上传
        handleBannerSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'banner', res.data.uri);
            }
        },
        beforeBannerUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },

        cellstyle() {
            return "text-align: center";
        },
        getClist(id) {
            this.queryParams.pid1 = this.Aid
            this.loadData(id) // 获取三级分页分类
        },
        getBlist() {
            this.queryParams.pid = this.fid;
            this.queryParams.pid2 = this.fid;
            this.Blist = [{ id: this.fid, name: "全部" }];
            this.loadBllData() // 获取二级全部分类
            this.loadData() // 获取二级分页分类
        },
        //一级分页列表
        loadData(id) {
            const _this = this
            this.loading = true;
            if (id) {
                this.queryParams.pid = id
            }
            // 获取商品分类列表
            request.get('/goods/classify/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //一级全部列表
        loadAllData() {
            let queryParams = {
                pid: 0,
                page: 1,
                pagesize: -1,
                platform: 'admin'
            }
            request.get('/goods/classify/list', queryParams).then(ret => {
                if (ret.code == 1) {
                    this.Alist = this.Alist.concat(ret.data.list);
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },
        //二级全部列表
        loadBllData() {
            let queryParams = {
                pid: this.fid,
                page: 1,
                pagesize: -1,
                platform: 'admin'
            }
            request.get('/goods/classify/list', queryParams).then(ret => {
                if (ret.code == 1) {

                    this.Blist = this.Blist.concat(ret.data.list);
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        //新增编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/goods/classify/add' : '/goods/classify/edit';

                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.loadData();
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        // 编辑
        edit(index, row) {
            this.addType= 'normal'
            this.mode = 'edit';
            this.title = '编辑商品分类';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'ctime', time);
            this.form.pid = this.form.pid + ''
            this.form.level = this.form.level + ''
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 新增
        add() {
            this.addType= 'normal'
            this.mode = 'add';
            this.title = '新增商品分类';
            this.form = {
                state: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        addChild(row) {
            console.log(row)
            this.mode = 'add';
            this.addType= 'addChild'
            this.title = '新增商品子分类';

            this.form.state = 2
            this.form.pid = row.id
            this.form.level = parseInt(row.level) + 1
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/goods/classify/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //
      handleImportSuccess(res, file) {
        loads.close();
        if (res.code === 1) {
          this.loadData();
        } else {
          this.$message.error(res.msg);
        }
      },
      beforeImportUpload(file) {
        let fileName = file.name
        let pos = fileName.lastIndexOf('.')
        let lastName = fileName.substring(pos, fileName.length)
        if (lastName.toLowerCase() !== '.xlsx') {
          this.$message.error('不支持此文件格式!');
        }
        loads.open(`body`, '导入中');
      },
    }
}
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
