<template>
    <div class="container">
        <div class="topTitle">
            <span>订单编号：{{pageInfo.ordersn}}</span>
            <span>下单时间：{{pageInfo.ctime}}</span>
            <span>普通订单</span>
        </div>
        <div class="aboutOrder">
            <el-row>
                <el-col :span="8" v-if="pageInfo.state ===0  && pageInfo.pay_state === 0">
                    <div class="grid-content part1">
                        <p>等待买家付款</p>
                        <p>等待买家完成付款，可主动与买家协调价格</p>
                        <div>
                            <el-button type="danger" @click="colseOrderVisible = true">取消订单</el-button>
                        </div>

                    </div>
                </el-col>
                <el-col :span="8" v-if="pageInfo.state ===0  && pageInfo.pay_state === 2 ">
                    <div class="grid-content part1">
                        <p>等待商户发货</p>
                        <p>请尽快处理发货，若无法发货请及时联系退款</p>
                        <div>
                            <el-button type="primary" @click="deliver()">发货</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" v-if="pageInfo.state ===1">
                    <div class="grid-content part1">
                        <p>等待商户发货</p>
                        <p>请尽快处理发货，若无法发货请及时联系退款</p>
                        <div>
                            <el-button type="primary" @click="deliver()">发货</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" v-if="pageInfo.state ===2">
                    <div class="grid-content part1">
                        <p>订单已完结</p>
                        <p>该笔订单已完成交易</p>
                        <div>
                            <el-button type="primary" @click="goDetail(0)">物流详情</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" v-if="pageInfo.state ===3">
                    <div class="grid-content part1">
                        <p>商户已发货，等待收货</p>
                        <p>若发现物流问题请及时修改物流信息保障用户权益</p>
                        <div>
                            <el-button type="primary" @click="goDetail(0)">查看/修改物流</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" v-if="pageInfo.state ===4 || pageInfo.state === 5 || pageInfo.state === 6">
                    <div class="grid-content part1">
                        <p>订单已关闭</p>
                        <p>订单已被用户/商户取消</p>
                        <div>
                            <el-button type="primary" @click="goDetail(0)">物流详情</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="16" v-if="pageInfo.state === 4 || pageInfo.state === 5 || pageInfo.state === 6">
                    <div class="grid-content part2">
                        <el-steps :active="active" finish-status="success" :process-status="status" align-center space="80%">
                            <el-step title="买家下单" description></el-step>
                            <el-step title="订单取消" description></el-step>
                            <el-step title="商户发货" description></el-step>
                            <el-step title="交易完成" description></el-step>
                        </el-steps>
                    </div>
                </el-col>
                <el-col :span="16" v-else>
                    <div class="grid-content part2">
                        <el-steps :active="active" finish-status="success" align-center space="80%">
                            <el-step title="买家下单" description></el-step>
                            <el-step title="买家付款" description></el-step>
                            <el-step title="商户发货" description></el-step>
                            <el-step title="交易完成" description></el-step>
                        </el-steps>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="logis" v-if="logs !== undefined &&logs.length>0">
            <el-tabs type="border-card" class="logis">
                <el-tab-pane v-for="(item,key) in logs" :key="key" :label="'物流'+(key+1)">
                    <div class="logisInfo">
                        <div class="infoLeft">
                            <div>
                                <span>快递公司：{{item.list.express?item.list.express:''}}</span>
                                <span class="infoLeft_item">
                                    最新物流状态：
                                    <span class="red" v-if="item && item.data">{{item.data[0].context}}</span>
                                </span>
                            </div>
                            <div>
                                <span>快递单号：{{item.list.expresssn}}</span>
                                <span class="infoLeft_item blue" @click="goDetail(key)">物流详情</span>
                            </div>
                            <div>发货时间：{{item.list.delivery_time}}</div>
                        </div>
                        <div class="goodsBox">
                            <div class="goods">
                                <img :src="item.list.thumb" />
                                <div class="ccontent">
                                    <p>{{item.list.title}}</p>
                                    <p class="gray">{{item.list.attr}}</p>
                                    <p class="num">
                                        <span>￥{{item.list.price}}</span>
                                        <span>x{{item.list.number}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="buyer" :gutter="30">
            <el-row>
                <el-col :span="8">
                    <div class="grid-content">
                        <div class="i-title">
                            <p>收货信息</p>
                            <p>跨境购信息</p>
                        </div>
                        <div class="item">
                            <p>收货人：</p>
                            <p>{{pageInfo.addr.username}}</p>
                        </div>
                        <div class="item">
                            <p>联系电话：</p>
                            <p>{{pageInfo.addr.phone}}</p>
                        </div>
                        <div class="item">
                            <p>收货地址：</p>
                            <p>
                                <span>{{pageInfo.addr.province}}</span>
                                <span>{{pageInfo.addr.city}}</span>
                                <span>{{pageInfo.addr.area}}</span>
                                <span>{{pageInfo.addr.address}}</span>
                            </p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div v-if="pageInfo.state===0" class="grid-content" style="border-left: 1px dashed rgb(199, 198, 198);border-right: 1px dashed rgb(199, 198, 198);">
                        <div class="i-title">
                            <p>付款信息</p>
                            <p></p>
                        </div>
                        <div class="item">
                            <p>待付款金额：</p>
                            <p>
                                ￥{{pageInfo.price}}
                                <!-- <span style="color:#FF4500">调整</span> -->
                            </p>
                        </div>
                        <div class="item">
                            <p>付款方式：</p>
                            <p>-</p>
                        </div>
                        <div class="item">
                            <p>付款时间：</p>
                            <p>-</p>
                        </div>
                    </div>
                    <div v-else class="grid-content" style="border-left: 1px dashed rgb(199, 198, 198);border-right: 1px dashed rgb(199, 198, 198);">
                        <div class="i-title">
                            <p>付款信息</p>
                            <p></p>
                        </div>
                        <div class="item">
                            <p>付款金额：</p>
                            <p v-if="pageInfo.pay_state===2">￥{{pageInfo.price}}</p>
                            <p v-else>-</p>
                        </div>
                        <div class="item">
                            <p>付款方式：</p>
                            <p v-if="pageInfo.pay_state===2">{{pageInfo.pay_type}}</p>
                            <p v-else>-</p>
                        </div>
                        <div class="item">
                            <p>付款时间：</p>
                            <p v-if="pageInfo.pay_state===2">{{pageInfo.pay_time}}</p>
                            <p v-else>-</p>

                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content">
                        <div class="i-title">
                            <p>配送方式</p>
                            <p></p>
                        </div>
                        <div class="item">
                            <p>配送方式：</p>
                            <p>物流快递</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="productsn" label="商品款号" align="center"></el-table-column>
            <el-table-column label="商品" align="center" width="300">
                <template slot-scope="scope">
                    <div class="goods">
                        <img :src="scope.row.thumb" />
                        <div class="ccontent">
                            <p>{{scope.row.title}}</p>
                            <p>{{scope.row.attr}}</p>
                        </div>
                        <!-- <div class="adjustment" @click="adjustment()">调整</div> -->
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="brand_name" label="品牌" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.brand_name">{{scope.row.brand_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="number" label="数量" width="150" align="center"></el-table-column>
            <el-table-column prop="goods_price" label="商品金额" width="100" align="center"></el-table-column>
            <el-table-column prop="price" label="实付金额" width="100" align="center"></el-table-column>
            <el-table-column prop="discount_price" label="优惠金额" width="100" align="center"></el-table-column>
            <el-table-column prop="commission_price" label="一级分佣" width="100" align="center"></el-table-column>
            <el-table-column prop="second_commission_price" label="二级分佣" width="100" align="center"></el-table-column>
            <el-table-column prop="is_refund" label="是否包含退货" width="150" align="center">
                <template slot-scope="scope" prop="refund_id">
                    <el-tag type="info" v-if="scope.row.refund_id === 0">不包含</el-tag>
                    <el-tag type="primary" v-else>包含</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="is_confirm" label="是否签收 " width="150" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.is_confirm === 0">未签收</el-tag>
                    <el-tag type="primary" v-if="scope.row.is_confirm === 2">已签收</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="is_settlement" label="佣金是否结算" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.is_settlement === 0">未结算</el-tag>
                    <el-tag type="primary" v-if="scope.row.is_settlement === 2">已结算</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="发货状态" prop="state" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.state === 0">未发货</el-tag>
                    <el-tag type="primary" v-if="scope.row.state === 2">已发货</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!-- 取消订单对话框 -->
        <el-dialog title="取消订单确认" :visible.sync="colseOrderVisible" width="300px" center>
            <div class="del-dialog-cnt">
                <p>确认取消该订单？</p>
                <p>取消后用户将不可支付该笔订单</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="colseOrderVisible = false">取 消</el-button>
                <el-button type="primary" @click="closeOrder">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { request } from '@/common/request';
import { deal } from '@/common/main';
import bus from '@/common/bus';
export default {
    name: 'orderDetails',
    data() {
        return {
            status: 'wait',
            colseOrderVisible: false,
            id: 0,
            context: '',
            active: 3,
            loading: false,
            pageInfo: {
                addr: {
                    username: '',
                }
            },
            tableData: [
            ],
            logs: []
        };
    },
    activated() {
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id != this.id && to.path == "/order-orderDetails") {
                    this.id = to.query.id;
                    this.pageInfo = {
                        addr: {
                            username: '',
                        }
                    },
                        this.loadData();
                }
                //深度监听，同时也可监听到param参数变化
            },
            deep: true,
            immediate: true
        }

    },
    methods: {
        loadData() {
            this.loading = true;
            const _this = this
            request.get('/order/detail', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.tableData = []
                    this.loading = false;
                    this.$set(this, 'pageInfo', ret.data.list)
                    // this.pageInfo = ret.data.list
                    // console.log(111111, this.pageInfo)
                    // this.pageInfo.addr = ret.data.list.addr
                    let list = ret.data.list;
                    this.pageInfo.pay_time = deal.timestamp(this.pageInfo.pay_time, 'unix')
                    if (this.pageInfo.state === 0) {
                        if (this.pageInfo.pay_state === 0) {
                            this.status = 'wait'
                            this.active = 1
                        }
                        if (this.pageInfo.pay_state === 2) {
                            this.status = 'wait'
                            this.active = 2
                        }
                    } else {
                        if (this.pageInfo.state === 1) {
                            this.status = 'wait'
                            this.active = 2
                        }
                        if (this.pageInfo.state === 2) {
                            this.status = 'wait'
                            this.active = 4
                        }
                        if (this.pageInfo.state === 3) {
                            this.status = 'wait'
                            this.active = 3
                        }
                        if (this.pageInfo.state === 4 || this.pageInfo.state === 5 || this.pageInfo.state === 6) {
                            this.active = 1
                            this.status = 'error'
                        }
                    }
                    // this.buyer = list.addr
                    // 获取商品列表
                    list.goods.map(item => {
                        return _this.tableData.push(item.list)
                    })
                    //获取商品对应物流信息
                    for (let i = 0; i < list.goods.length; i++) {
                        console.log(444444, list.goods[i].list.expresscom, list.goods[i].list.expresssn)
                        if (list.goods[i].list.expresscom && list.goods[i].list.expresssn) {
                            _this.loadLogInfo(list.goods[i].list.expresscom, list.goods[i].list.expresssn)
                        }
                    }

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        //获取物流信息
        loadLogInfo(expresscom, expresssn) {
            console.log(66666)
            request.get('/order/logistics', { expresscom: expresscom, expresssn: expresssn }).then(ret => {
                if (ret.code == 1) {
                    if (ret.data.list && ret.data.express) {
                        this.logs.push(ret.data.list)
                        this.logs.map((items, index) => {
                            if (items.data.length > 0) {
                                return items.data.map((item, index) =>
                                    item.color = '#20a0ff'
                                )
                            }
                        })
                        this.logs.map((item, key) => {
                            item = Object.assign(item, this.pageInfo.goods[key])
                            item.list.delivery_time = deal.timestamp(item.list.delivery_time, 'unix')
                            return item
                        })
                        console.log(33333, logs)
                    } else {
                        return
                    }

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //取消订单
        closeOrder() {
            request.post('/order/clone/order', { id: this.id, state: 4 }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('取消成功');
                    bus.$emit('close_current_tags');
                    this.$router.push({ path: '/order-orderList' })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 发货
        deliver() {
            this.$router.push({ path: '/order-Deliver', query: { id: this.id } })

        },
        // 物流详情
        goDetail(key) {
            this.$router.push({ path: '/order-logistics', query: { id: this.id, 'key': key + '' } })
        },
        // 调整
        // adjustment () {

        // }

    },
};
</script>

<style lang="scss" scoped>
.red {
    color: red;
}
.topTitle {
    color: #666;
    font-size: 14px;

    span:nth-child(2) {
        padding: 0 15px;
    }
}

.aboutOrder {
    border: 1px solid rgb(199, 198, 198);
    height: 157px;
    margin-top: 15px;
    .part1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgb(199, 198, 198);
        // padding: 30px 0;
        height: 157px;

        p:nth-child(1) {
            font-size: 25px;
            text-align: center;
        }

        p:nth-child(2) {
            font-size: 13px;
            text-align: center;
            color: #666;
            padding-top: 5px;
        }

        div {
            text-align: center;
            margin-top: 10px;
        }
    }

    .part2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 30px 0;
        height: 157px;

        .first {
            width: 75px;
            text-align: center;

            p:nth-child(1) {
                padding-bottom: 10px;
            }

            .date {
                padding-top: 10px;
                color: #666;
                font-size: 13px;
                height: 40px;
            }
        }

        .success {
            color: #409eff;
            font-size: 30px;
        }

        .line {
            width: 100px;
            height: 4px;
            background: #409eff;
            margin-right: 10px;
        }

        .time {
            color: #999;
            font-size: 30px;
        }

        .lines {
            width: 100px;
            height: 4px;
            background: #999;
            margin: 0 10px;
        }
    }
}

.buyer {
    border: 1px solid rgb(199, 198, 198);
    margin-top: 10px;
    padding: 30px 0;

    .i-title {
        text-align: center;
        font-size: 15px;
        display: flex;

        p {
            flex: 1;
            text-align: center;
        }

        p:nth-child(2) {
            color: #666;
            font-size: 13px;
        }
    }

    .item {
        display: flex;
        font-size: 13px;
        color: #666;
        margin-top: 10px;

        p:nth-child(1) {
            flex: 1;
            text-align: right;
        }

        p:nth-child(2) {
            flex: 2;
        }
    }

    .content {
        border-left: 1px dashed rgb(199, 198, 198);
    }
}

.goods {
    display: flex;

    img {
        width: 50px;
        height: 50px;
    }

    .ccontent {
        padding: 0 10px;
        color: #666;
    }

    .adjustment {
        color: #ff4500;
    }
}
.el-steps {
    width: 100%;
}
.logis {
    margin-top: 20px;
}

.logisInfo {
    width: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 20px;

    .infoLeft {
        div {
            color: #999;
            font-size: 12px;
            line-height: 25px;
            .infoLeft_item {
                margin-left: 100px;
            }
            .blue {
                cursor: pointer;
                color: blue;
            }
        }
    }
    .goodsBox {
        display: flex;
        .goods {
            border: 1px solid #eeeeee;
            padding: 10px;
            margin: 0 20px;
        }
        .ccontent {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p {
                font-size: 12px;
                color: #000;
            }
            .gray {
                color: gray;
            }
        }
    }
    .num {
        display: flex;
        justify-content: space-between;
    }
}
</style>